import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // 打包时打开
  // history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),  // 开发时打开
  // history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home.vue'),
      meta: {

        title: '城安域通',
      }
    },

    {
      path: '/404',
      name: '404',
      component: () => import('@/views/404.vue'),
      meta: {
        title: '404',
      }
    }
  ]
})

export default router
