<template>
  <div id="app">
    <!-- <leftNav /> -->
    <div class=" h-body ">
      <router-view />
    </div>
    <!-- <FooterQnav /> -->
  </div>
</template>

<script>
// import LeftNav from "@/components/LeftNav.vue";
// import FooterQnav from "@/components/footerQnav.vue";

export default {
  name: "App",
  components: {
    // LeftNav,
    // FooterQnav,
  },
};
</script>

<style lang="scss">
@import '@/assets/css/bootstrap.min.css';
@import '@/assets/css/iconfont.css';
@import '@/assets/css/style.css';
$leftw: 80px;
$miniLeftw: 20px;

#app {
  .h-body {
    // background: #f5f8fb;
    background: #ffffff;
    min-height: 100vh;
    // height: 100vh;
    width: 100%;
  }

  .box {
    min-height: 100vh;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: $leftw;
    position: relative;
    display: flex;
    align-items: center;

    .titleBox {

      display: flex;
      justify-content: start;
      margin-bottom: 10px;

      .text-title {
        // text-align: center;
        margin-bottom: 40px;
        padding-right: 30px;
        margin-right: 30px;
        border-right: 2px solid rgba(0, 0, 0, .3);

        h2 {
          font-size: 1.8vw !important;
          line-height: 1.4;
          margin-bottom: 4px;

        }

        small {
          font-size: 1.1rem;
          opacity: 0.5;
          font-weight: 200;
          line-height: 0.8;
        }
      }

      .text-subtitle {
        p {
          margin-bottom: 0px;
        }

        .small {
          font-size: 0.9rem;
          opacity: 0.5;
          font-weight: 200;
          line-height: 1.1;
        }
      }



    }
  }

  .box.ash {
    background-color: #f7f7f7;
  }

  .grid {
    position: relative;
    clear: both;
    margin: 0 auto;
    padding: 0em 0 4em;
    max-width: 1000px;
    list-style: none;

    figure {
      position: relative;
      float: left;
      overflow: hidden;
      margin: 10px 1%;

      width: 100%;
      height: auto;
      background: #3085a3;
      text-align: center;
      cursor: pointer;

      img {
        position: relative;
        display: block;
        min-height: 100%;
        max-width: 100%;
        opacity: 0.8;
      }

      figcaption {
        padding: 1.8rem 0.5rem;
        color: #fff;
        // text-transform: uppercase;
        font-size: 1.25em;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;


        h2 {
          word-spacing: 0.15em;
          font-weight: 300;
          font-size: 0.8rem;
          line-height: 1.2;
          margin: 0;


          span {
            font-weight: 800;
            font-size: 1.6rem;

          }
        }

        .con_box {
          margin: 10px;
          letter-spacing: 1px;
          text-align: start;

          p {
            font-size: 0.65rem !important;
            margin-bottom: 2px;
          }
        }
      }

      figcaption::before,
      figcaption::after {
        pointer-events: none;
      }


    }
  }

  /*---------------*/
  /***** Duke *****/
  /*---------------*/

  figure.effect-duke {
    background: -webkit-linear-gradient(-45deg, #004cff 0%, #0e02b7 100%);
    background: linear-gradient(-45deg, #006dda 0%, #00059d 100%);
    border-radius: 0.45rem;
    overflow: hidden;
  }

  figure.effect-duke img,
  figure.effect-duke .con_box {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
  }

  figure.effect-duke:hover img {
    opacity: 0.1;
    -webkit-transform: scale3d(2, 2, 1);
    transform: scale3d(2, 2, 1);
  }

  figure.effect-duke h2 {
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: scale3d(0.8, 0.8, 1);
    transform: scale3d(0.8, 0.8, 1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  figure.effect-duke .con_box {
    position: absolute;
    top: 22%;
    left: 0;
    margin: 10px;
    padding: 10px;
    // border: 1px solid rgba(255, 255, 255, .6);
    border-radius: 4px;
    text-transform: none;
    font-size: 0.65rem !important;
    opacity: 0;
    -webkit-transform: scale3d(0.8, 0.8, 1);
    transform: scale3d(0.8, 0.8, 1);
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
  }

  figure.effect-duke:hover h2,
  figure.effect-duke:hover .con_box {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  /*---------------*/
  /***** Julia *****/
  /*---------------*/

  figure.effect-julia {
    background: #2f3238;
    border-radius: 0.5rem;
  }

  figure.effect-julia img {
    max-width: none;
    width: 100%;
    -webkit-transition: opacity 1s, -webkit-transform 1s;
    transition: opacity 1s, transform 1s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

  }

  figure.effect-julia figcaption {
    text-align: left;
  }

  figure.effect-julia h2 {
    position: relative;
    padding: 0.5em 1rem;
    color: #000;
    font-family: din_alternatebold;

  }

  figure.effect-julia p {
    display: inline-block;
    margin: 0;
    padding: 1em 1em 0.4em 1em;
    background: rgba(255, 255, 255, 0.8);
    color: #2f3238;
    text-transform: none;
    font-weight: 300;
    font-size: 75%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-92vw, 0, 0);
    transform: translate3d(-92vw, 0, 0);
  }

  figure.effect-julia p:first-child {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
  }

  figure.effect-julia p:nth-of-type(2) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }

  figure.effect-julia p:nth-of-type(3) {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
  }

  figure.effect-julia:hover p:first-child {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }

  figure.effect-julia:hover p:nth-of-type(2) {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
  }

  figure.effect-julia:hover p:nth-of-type(3) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }

  figure.effect-julia:hover img {
    opacity: 0.4;
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }

  figure.effect-julia:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  /*-----------------*/
  /***** Steve *****/
  /*-----------------*/

  figure.effect-steve {
    z-index: auto;
    overflow: visible;
    background: #fff;
  }

  figure.effect-steve:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    content: '';
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
  }

  figure.effect-steve:before {
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.8);
    opacity: 0;
  }

  figure.effect-steve figcaption {
    z-index: 1;
  }

  figure.effect-steve img {
    opacity: 1;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: perspective(1000px) translate3d(0, 0, 0);
    transform: perspective(1000px) translate3d(0, 0, 0);
  }

  figure.effect-steve:hover:before {
    opacity: 1;
  }

  figure.effect-steve:hover img {
    -webkit-transform: perspective(1000px) translate3d(0, 0, 100px);
    transform: perspective(1000px) translate3d(0, 0, 100px);
  }

  /****/
  $time: 20s;

  .slideshow {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .slideshow-image {

    .imgBgs {
      position: absolute;
      width: 100%;
      height: 100%;
      background: no-repeat 50% 50%;
      background-size: cover;
      -webkit-animation-name: kenburns;
      animation-name: kenburns;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-duration: $time;
      animation-duration: $time;
      opacity: 1;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }

    .pcImg {}

    .miniImg {
      display: none;
    }

    .textDiv1,
    .textDiv2,
    .textDiv3,
    .textDiv4 {
      -webkit-animation-name: kenburns-s;
      animation-name: kenburns-s;
      -webkit-animation-timing-function: step-start;
      animation-timing-function: step-start;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-duration: $time;
      animation-duration: $time;
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }

  }

  .slideshow-image:nth-child(1) {
    .imgBgs {
      -webkit-animation-name: kenburns-1;
      animation-name: kenburns-1;
      z-index: 6;
    }

    .textDiv1 {
      -webkit-animation-name: kenburns-1;
      animation-name: kenburns-1;
      z-index: 7;
    }
  }

  .slideshow-image:nth-child(2) {
    .imgBgs {
      -webkit-animation-name: kenburns-2;
      animation-name: kenburns-2;
      z-index: 4;
    }

    .textDiv2 {
      -webkit-animation-name: kenburns-2;
      animation-name: kenburns-2;
      z-index: 5;
    }
  }

  .slideshow-image:nth-child(3) {
    .imgBgs {
      -webkit-animation-name: kenburns-3;
      animation-name: kenburns-3;
      z-index: 2;
    }

    .textDiv3 {
      -webkit-animation-name: kenburns-3;
      animation-name: kenburns-3;
      z-index: 3;
    }

  }

  .slideshow-image:nth-child(4) {
    .imgBgs {
      -webkit-animation-name: kenburns-4;
      animation-name: kenburns-4;
      z-index: 0;
    }

    .textDiv4 {
      -webkit-animation-name: kenburns-4;
      animation-name: kenburns-4;
      z-index: 1;
    }
  }

  @media (max-width: 720px) {
    .box {

      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: $miniLeftw;

      .titleBox {
        margin-bottom: 10px;

        .text-title {
          margin-bottom: 10px;
          padding-right: 15px;
          margin-right: 15px;
          min-width: 26vw;
          border-right: 0px solid rgba(0, 0, 0, .3);

          h2 {
            font-size: 1.3rem !important;
            line-height: 1.4;
            margin-bottom: 0;
          }

          small {
            font-size: 1.1rem;
            opacity: 0.5;
            font-weight: 200;
            line-height: 0.8;
          }


        }

        .text-subtitle {
          display: none;
          padding-right: 20px;

          p {
            margin-bottom: 0px;
          }

          .small {
            font-size: 0.9rem;
            opacity: 0.5;
            font-weight: 200;
            line-height: 1.1;
          }
        }
      }
    }

    .slideshow-image {

      .imgBgs {
        .pcImg {
          display: none;
        }

        .miniImg {
          display: block;
        }

      }
    }
  }


  @-webkit-keyframes kenburns-1 {
    0% {
      opacity: 1;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }

    1.5625% {
      opacity: 1;
    }

    23.4375% {
      opacity: 1;
    }

    26.5625% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    100% {
      opacity: 0;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }

    98.4375% {
      opacity: 0;
      -webkit-transform: scale(1.21176);
      transform: scale(1.21176);
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes kenburns-1 {
    0% {
      opacity: 1;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }

    1.5625% {
      opacity: 1;
    }

    23.4375% {
      opacity: 1;
    }

    26.5625% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    100% {
      opacity: 0;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }

    98.4375% {
      opacity: 0;
      -webkit-transform: scale(1.21176);
      transform: scale(1.21176);
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes kenburns-2 {
    23.4375% {
      opacity: 1;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }

    26.5625% {
      opacity: 1;
    }

    48.4375% {
      opacity: 1;
    }

    51.5625% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    100% {
      opacity: 0;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  @keyframes kenburns-2 {
    23.4375% {
      opacity: 1;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }

    26.5625% {
      opacity: 1;
    }

    48.4375% {
      opacity: 1;
    }

    51.5625% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    100% {
      opacity: 0;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  @-webkit-keyframes kenburns-3 {
    48.4375% {
      opacity: 1;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }

    51.5625% {
      opacity: 1;
    }

    73.4375% {
      opacity: 1;
    }

    76.5625% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    100% {
      opacity: 0;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  @keyframes kenburns-3 {
    48.4375% {
      opacity: 1;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }

    51.5625% {
      opacity: 1;
    }

    73.4375% {
      opacity: 1;
    }

    76.5625% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    100% {
      opacity: 0;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  @-webkit-keyframes kenburns-4 {
    73.4375% {
      opacity: 1;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }

    76.5625% {
      opacity: 1;
    }

    98.4375% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes kenburns-4 {
    73.4375% {
      opacity: 1;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }

    76.5625% {
      opacity: 1;
    }

    98.4375% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @-webkit-keyframes kenburns-s {
    0% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    1.5625% {
      opacity: 1;
    }

    23.4375% {
      opacity: 1;
    }

    26.5625% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    100% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    98.4375% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes kenburns-s {
    0% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    1.5625% {
      opacity: 1;
    }

    23.4375% {
      opacity: 1;
    }

    26.5625% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    100% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    98.4375% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    100% {
      opacity: 1;
    }
  }


}
</style>
